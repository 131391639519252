/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }
// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

i {
  color: white;
}

footer {
  border-top: 10px solid;
  border-color: #00aeff;
}

.nav-link {
  color: black !important;
}

/* Index */

header.masthead {
  position: relative;
  background: url("../public/img/banner-alt.jpg") no-repeat center center;
  background-size: cover;
  margin-top: 3.5rem;
  padding: 8rem 0;
}

header.masthead h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

@media (min-width: 768px) {
  header.masthead {
    padding: 12rem 0;
  }

  header.masthead h1 {
    font-size: 5rem;
  }

  header.masthead h3 {
    font-size: 1.5rem;
  }
}

.announcements {
  border-bottom: 10px solid;
  border-color: #f22626;
}

/* About */

.banner {
  margin-top: 4rem;
  border-bottom: 10px solid;
  border-color: #f22626;
}

.affimg {
  width: 150px;
  height: 150px;
}

/* FAQ */

.faq, .terms {
  margin-top: 4rem;
}

.terms {
  min-height: 100vh;
}